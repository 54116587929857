import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { FileDropzone, File } from 'src/shared/components/file-drop-zone';
import { ArtifactFileType, ArtifactFileTypeDisplayNames } from 'src/models/artifact-file';
import { FormSelect, FormSelectItem } from 'src/shared/components/form/form-select';
import UIMessage from 'src/shared/components/ui/ui-message';
import {ArtifactDetailTags} from './artifact-tags';
import { ArtifactModel } from 'src/models/artifact';


const artifactImageFileTypes: FormSelectItem<number>[] = [
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageDefault],
        value: ArtifactFileType.ImageDefault
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageObverse],
        value: ArtifactFileType.ImageObverse
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageReverse],
        value: ArtifactFileType.ImageReverse
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImagePreConservation],
        value: ArtifactFileType.ImagePreConservation
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageCard],
        value: ArtifactFileType.ImageCard
    },
    {
        label: ArtifactFileTypeDisplayNames[ArtifactFileType.ImageAdditional],
        value: ArtifactFileType.ImageAdditional
    }
];

export interface ArtifactImageUploadResult {
    success: boolean;
    errors?: string[]
}

interface ArtifactImageUploaderProps {
    artifact?: ArtifactModel;
    onUpload: (file: File, fileType: ArtifactFileType) => Promise<ArtifactImageUploadResult>;
    defaultFileType?: ArtifactFileType;
    onClose?: () => void;
    open?: boolean;
}

export const ArtifactImageUploader: FC<ArtifactImageUploaderProps> = (props) => {
    const { onUpload, onClose, artifact, defaultFileType = ArtifactFileType.ImageObverse, open = false } = props;
    const [fileType, setFileType] = useState<ArtifactFileType>(defaultFileType)
    const [errors, setErrors] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


    useEffect(() => {
        setErrors([]);
        setIsSubmitting(false)
    }, []);

    const handleUpload = async (files: File[]): Promise<void> => {
        if (!files || files.length === 0) return;
        setIsSubmitting(true);
        const result = await onUpload(files[0], fileType);
        setIsSubmitting(false);
        if (result.success) {
            onClose?.();
        } else if (result.errors) {
            setErrors(result.errors);
        }
    };

    const handleFileTypeChanged = (key: string, value: ArtifactFileType): void => {
        setFileType(value);
    };

    const handleClose = (event: string, reason: string): void => {
        if (reason !== 'backdropClick') {
            onClose?.();
        }
    };


    return (

        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: 100 }}
                open={isSubmitting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack spacing={3}
                sx={{
                    px: 3,
                    py: 2
                }}>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between">

                    <Typography variant="h6">
                        Upload Artifact Image
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={onClose}
                        disabled={isSubmitting}
                    >
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Stack>
                <ArtifactDetailTags artifact={artifact} />
            </Stack>

            <DialogContent>
                <UIMessage
                    type="error"
                    messages={errors}
                    onClose={() => setErrors([])} />
                <Stack spacing={2}>
                    <FormSelect
                        label="Artifact Image Type"
                        name="artifactImageFileType"
                        initialValue={defaultFileType}
                        datasource={artifactImageFileTypes}
                        onChange={handleFileTypeChanged}
                        disabled={isSubmitting}
                    />
                </Stack>
                <Stack spacing={2}>
                    <FileDropzone
                        maxFiles={1}
                        maxSize={10000000}
                        accept={{ 'image/jpeg': [] }}
                        caption="Jpeg, Max file size is 10 MB"
                        onUpload={handleUpload}
                        disabled={isSubmitting}
                    />
                </Stack>
            </DialogContent>
        </Dialog>

    );
};

